import { reducerAndActionsFor } from '@symbolic/redux';
import { prepareToAnimate } from '@symbolic/rn-lib';

var {reducer, actions} = reducerAndActionsFor('activeView', {
  setActiveView: async ({data={}, getState}) => {
    prepareToAnimate();

    return {data: {...getState().activeView.data, ...data}};
  }
});

var {setActiveView} = actions;

export default reducer;
export {setActiveView};
