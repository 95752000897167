import { reducerAndActionsFor } from '@symbolic/redux';
import _ from 'lodash';

var {reducer, actions} = reducerAndActionsFor('activeView', {
  setEvent: async ({event}) => ({...event, uuid: _.uniqueId()})
});

var {setEvent} = actions;

export default reducer;
export {setEvent};
